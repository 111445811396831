<template>
  <!-- NAME[epic=报表] 市场投入率统计 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 240px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="type">
        <el-select
          v-model="form.type"
          clearable
          style="width: 140px"
          placeholder="销售类型"
        >
          <el-option
            v-for="(i, idx) in saleSelect"
            :key="idx"
            :value="i.name"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="staff_id">
        <el-select
          v-model="form.staff_id"
          clearable
          style="width: 140px"
          placeholder="业务员"
        >
          <el-option
            v-for="(i, idx) in staffList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="brand">
        <brand-select
          ref="brandSelect"
          style="width: 160px"
          :popper-class="'select-idx'"
          @brand-select-change="chooseBrand"
        ></brand-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <goods-search
          ref="goodsSearch"
          :is-table="false"
          @select-goods-all="goodsSelect"
        ></goods-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search
          ref="clientSearch"
          :popper-class="'selectDC'"
          @select-id="clientSelect"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="keyword">
        <el-input v-model="form.keyword" placeholder="备注"></el-input>
      </el-form-item>
      <el-button
        type="primary"
        @click.stop="
          () => {
            form.pageSize = 10
            form.pageNo = 1
            fetchData()
          }
        "
      >
        查询
      </el-button>
      <el-button type="warning" @click.stop="clearForm">清空</el-button>
      <el-button @click="handleExport">导出</el-button>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      style="margin-top: 15px"
      show-summary
      :summary-method="summaryFunction"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '单号'">
            <span class="underline" @click="handleCheckOrder(row)">
              {{ row[item.prop] }}
            </span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <order-detail ref="orderDetail"></order-detail>
  </div>
</template>
<script>
  import _ from 'lodash'
  import orderDetail from '@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue'
  import { marketInputList } from '@/api/report'
  import { getStaffList } from '@/api/setPrice'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { downloadFile, postAction } from '@/api/Employee'
  import BrandSelect from '@/baseComponents/brandSelect'

  export default {
    name: 'MarketInput',
    components: { GoodsSearch, ClientSearch, orderDetail, BrandSelect },
    data() {
      return {
        loading: false,
        time: [],
        saleSelect: [],
        staffList: [],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        list: [],
        checkList: [
          '单号',
          '客户名称',
          '类型',
          '商品名称',
          '单位',
          '单价',
          '成本',
          '数量',
          '货值金额',
          '成本金额',
          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '单号',
            prop: 'bill_code',
            width: '',
          },
          {
            order: 2,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 3,
            label: '类型',
            prop: 'type',
            width: '',
          },
          {
            order: 4,
            label: '商品名称',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 5,
            label: '单位',
            prop: 'unit_name',
            width: '',
          },
          {
            order: 6,
            label: '单价',
            prop: 'goods_price',
            width: '',
          },
          {
            order: 7,
            label: '成本',
            prop: 'base_price',
            width: '',
          },
          {
            order: 8,
            label: '数量',
            prop: 'quantity',
            width: '',
          },
          {
            order: 9,
            label: '货值金额',
            prop: 'value_amount',
            width: '',
          },
          {
            order: 10,
            label: '成本金额',
            prop: 'base_amount',
            width: '',
          },
          {
            order: 11,
            label: '备注',
            prop: 'info',
            width: '',
          },
        ],
        form: {
          pageNo: 1,
          pageSize: 10,
          sort: '',
          order: '',
          start_time: '',
          end_time: '',
          type: '', //优惠、兑付现金、兑奖现金、赠品、兑付、兑奖、协议赠品尝品、
          staff_id: '',
          goods_id: '',
          cust_id: '',
          keyword: '', //备注
        },
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.fetchData()
      // 销售类型下拉
      postAction('/reportAdmin/analysis/type').then((res) => {
        this.saleSelect = res.data
      })
      // 获取业务员下拉
      getStaffList().then((res) => {
        if (res.code == 200) this.staffList = res.data
      })
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await marketInputList(this.form)
        this.total = totalCount
        this.list = data
        this.loading = false
      },
      goodsSelect(val) {
        this.form.goods_id = val.goods_id
      },
      clientSelect(val) {
        this.form.cust_id = val
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      clearForm() {
        this.time = []
        this.$refs.form.resetFields()
        this.$refs.goodsSearch.resetForm()
        this.$refs.clientSearch.resetForm()
        this.form.goods_id = ''
        this.form.cust_id = ''
      },
      chooseBrand(val) {
        this.form.brand_id = val
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [9, 10]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                sums[n] = a.toFixed(2) + ' 元'
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
      handleExport() {
        downloadFile(
          '/reportAdmin/analysis/market-input-export',
          '市场投入率.xlsx',
          this.form
        )
      },
      handleCheckOrder(row) {
        this.$refs['orderDetail'].id = Number(row.id)
        this.$refs['orderDetail'].isshowDialog = true
      },
    },
  }
</script>
<style lang="scss" scoped></style>
